<script setup lang="ts">
import { computed } from '#imports';

export interface IRatingProps {
  rating: number | string;
  size?: 'small' | 'normal' | 'large';
  wrapperClass?: string;
  iconClass?: string;
}

const props = withDefaults(defineProps<IRatingProps>(), {
  rating: 0,
  size: 'normal',
});

const processedRating = computed(() => {
  const roundedRating = +props.rating | 0;
  const rating = [];

  for (let i = 0; i < 5; i++) {
    if (i < roundedRating) rating.push('star');
    else if (Number(props.rating) - i > 0 && Number(props.rating) - i < 1) rating.push('half-star');
  }

  return rating;
});
</script>

<template>
  <div
    v-if="!!processedRating.length"
    class="rating"
    :class="[props.size, wrapperClass]"
  >
    <ApzIcon
      v-for="(icon, index) in processedRating"
      :key="index"
      :class="['rating-star', iconClass]"
      :icon="['fas', icon]"
    />
  </div>
</template>

<style lang="scss" scoped>
.rating {
  @apply flex h-15;

  .rating-star {
    @apply text-secondary-dark text-base;
  }

  &.small {
    @apply h-10;

    .rating-star {
      @apply text-xsmall;
    }
  }

  &.normal {
    @apply h-[12px];

    .rating-star {
      @apply text-small;
    }
  }
}
</style>
